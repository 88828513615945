import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpThemeProvider,
  ownUpWhiteTheme,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { Script } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Layout } from '../components/layout';
import ExperianLogo from '../images/experian.png';
import OwnupLogo from '../images/own-up-full-logo.svg';

const LenderTableWrapper = styled.div`
  min-height: 80vh;
  padding: 0 !important;
  & > div > div.\\@container {
    margin: 0;
    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down(370)} {
      & > div {
        padding: 2rem 0;
        background-color: transparent;
      }
    }
  }
`;
const LogosContainer = styled(OwnUpGridContainer)`
  margin-bottom: 8px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin-bottom: 40px;
  }
`;
const OwnUpLogoWrapper = styled(OwnUpGridItem)`
  display: flex;
  justify-content: center;
  img {
    max-width: 190px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    justify-content: initial;
  }
`;
const ExperianLogoWrapper = styled(OwnUpGridItem)`
  display: flex;
  justify-content: center;
  img {
    max-width: 230px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    flex-direction: row-reverse;
    justify-content: initial;
    img {
      max-width: 363px;
    }
  }
`;
const Headline = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    font-size: 48px;
    line-height: 56px;
    text-align: left;
  }
`;

const LenderTable = () => {
  return (
    <Layout isLanding>
      <Script
        src={`${process.env.GATSBY_OWNUP_LENDER_TABLE_WIDGET}`}
        onLoad={() => {
          if (window?.ownUpWidgets) {
            window?.ownUpWidgets['widgets/lender-table/injectable'].inject('#own-up-lender-table');
          }
        }}
      ></Script>
      <OwnUpThemeProvider theme={ownUpWhiteTheme}>
        <section aria-label="Lender-table">
          <OwnUpGridWrapper>
            <LogosContainer variant="legacy">
              <OwnUpLogoWrapper xs={12} md={6}>
                <img src={OwnupLogo} alt="ownup.com" />
              </OwnUpLogoWrapper>
              <ExperianLogoWrapper xs={12} md={6}>
                <img src={ExperianLogo} alt="experian.com" />
              </ExperianLogoWrapper>
            </LogosContainer>
            <OwnUpGridContainer variant="legacy">
              <OwnUpGridItem xs={12}>
                <Headline>A great mortgage rate is a few clicks away</Headline>
              </OwnUpGridItem>
              <OwnUpGridItem xs={12}>
                <LenderTableWrapper id="own-up-lender-table"></LenderTableWrapper>
              </OwnUpGridItem>
            </OwnUpGridContainer>
          </OwnUpGridWrapper>
        </section>
      </OwnUpThemeProvider>
    </Layout>
  );
};

export default LenderTable;
